import type { ProviderGetImage } from '@nuxt/image';

// https://shopify.dev/docs/api/liquid/filters/img_url#img_url-size
export const getImage: ProviderGetImage = (src, { modifiers = {} } = {}) => {
    let sizeModifier = '';
    if (modifiers.width) sizeModifier = `${modifiers.width}`;
    if (modifiers.height) sizeModifier += `x${modifiers.height}`;
    // if only width is modified, we need to add 'x'
    else sizeModifier += sizeModifier.length ? 'x' : '';

    if (sizeModifier) sizeModifier = `_${sizeModifier}`;

    const url = src.replace('.jpg?', `${sizeModifier}.jpg?`).replace('.png?', `${sizeModifier}.png?`);
    return {
        url,
    };
};
