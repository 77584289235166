interface ToastProps {
    position: string;
    message: {
        severity: 'success' | 'info' | 'warn' | 'error';
        summary: string;
        [key: string]: any;
    };
}

export default {
    root: () => {
        return {
            class: 'md:max-w-md z-3xl w-[calc(100%-40px)] md:m-4',
        };
    },
};
