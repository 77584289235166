const queryKeys = ['preview', 'cache_update'];
export const usePreview = () => {
    const { enabled } = usePreviewMode();
    const { query } = useRoute();
    const queryParams = computed(() => {
        const params: Record<string, (typeof query)[string]> = {};
        queryKeys.forEach((key) => {
            const value = query[key];
            if (value) params[key] = value;
        });
        return params;
    });

    const addPreview = (urlString: string) => {
        const delimiter = urlString.includes('?') ? '&' : '?';
        const previewParams = Object.entries(queryParams.value)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return value.map((param) => `${key}=${param}`);
                }
                return `${key}=${value}`;
            })
            .flat()
            .join('&');
        return `${urlString}${delimiter}${previewParams}`;
    };

    return {
        addPreview,
        previewEnabed: enabled,
        queryParams,
    };
};
