import type { LocationQueryValue } from '#vue-router';

export function isDefined<T>(argument: T | undefined | null): argument is T {
    return Boolean(argument);
}
/**
 * ### wait function
 *
 * @param ms number auf milliseconds until promise resolves (default = 1)
 * @returns Promise that resolves after time expires
 */
export const wait = (ms = 1) => new Promise((resolve) => setTimeout(resolve, ms));

export const paramsArrayToString = (input: string | string[]): string => {
    return Array.isArray(input) ? input[0] : input;
};

export const queryArrayToString = (input: LocationQueryValue | LocationQueryValue[]): string | null => {
    if (!input) {
        return input;
    } else {
        const value = Array.isArray(input) ? input[0] : input;
        return value ? value.toLowerCase() : value;
    }
};

export const unArray = <T>(maybeArray: T | T[]) => {
    return Array.isArray(maybeArray) ? maybeArray[0] : maybeArray;
};
export const arrayify = <T>(maybeArray: T | T[]) => {
    return Array.isArray(maybeArray) ? maybeArray : [maybeArray];
};

export const throttle = <R, A extends any[]>(fn: (...args: A) => R, delay: number): ((...args: A) => R | undefined) => {
    let wait = false;
    let timeout: undefined | number;

    return (...args: A) => {
        if (wait) return undefined;

        const val = fn(...args);

        wait = true;

        timeout = window.setTimeout(() => {
            wait = false;
            // TODO: check how to return value one more time (maybe options)
            fn(...args);
        }, delay);

        return val;
    };
};

export function debounce<Params extends any[]>(func: (...args: Params) => any, timeout: number): (...args: Params) => void {
    var timer: ReturnType<typeof setTimeout>;
    return (...args: Params) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func(...args);
        }, timeout);
    };
}
