import type { AuthorData } from '~/@types/cms';
import type { ButtonProps } from '~/components/atm/Button.vue';
import type { CallToAction, MenuItem } from '~/graphql/generated';

/**
 * There is currently no reflection on nuxt to get the widgets
 * resolveComponent is a compiler function that requires a static string and no variable
 * The proper way would be to create hooks, listen to all registered components and store those
 * due to time constraints we will revert to a Name lookup
 */
const placeholderMap: Record<string, string> = {
    awards: 'AwardsWidget',
    bikefinder: 'BikefinderWidget',
    specification: 'SpecificationWidget',
    geometry: 'GeometryWidget',
    nowcountdown: 'FeatureSliderWidget',
};

let availableComponents: Record<string, globalThis.Component> | undefined;

export const resolveWidget = (name: string): undefined | string => {
    if (!availableComponents) {
        // getCurrentInstance will return null outside of the context of the `setup` function
        // Caused by `pages/[...slug].vue` > `useAsyncData` running again when hash changes
        // This is why we cache the information we need
        const instance = getCurrentInstance();
        availableComponents = instance?.appContext?.components;
    }
    // some naming conversions for the Placeholder Widgets
    const key = placeholderMap[name] || name;

    if (availableComponents?.[`Cms${key}`]) {
        return `Cms${key}`;
    }
    return undefined;
};

export const resolveTag = (ref: any, refLookup: RefLookupFunction): string | undefined => {
    if (!ref) return;
    if (typeof ref === 'object') throw new Error('not implemented yet - tag object');
    if (typeof ref !== 'string') return;
    const tagRef = refLookup<{ id: string; title: string }>(ref, 'tags');
    return tagRef?.title;
};

export const resolveAuthor = (ref: any, refLookup: RefLookupFunction): AuthorData | undefined => {
    if (!ref) return;
    if (typeof ref === 'object') throw new Error('author as object', ref);
    if (typeof ref !== 'string') return;
    const refData = refLookup<{ id: string; name: string; portrait: string }>(ref, 'authors');
    if (refData) {
        return {
            name: refData.name,
            image: resolveAssetSrc(refData.portrait),
        };
    }
};

/**
 * ### Checks widgets that are extended with Widget Restrictions Snippet
 * - if start & end => start <= now &&  end >= now
 * - if start => start <= now
 * - if end => end >= now
 * - else => pass through
 */
export const shouldDisplayWidget = <T extends { restrictions?: { start?: string; end?: string } }>(widget: T, ..._: any[]) => {
    const now = new Date();
    const startValid = widget.restrictions?.start ? new Date(widget.restrictions.start) <= now : true;
    const endValid = widget.restrictions?.end ? new Date(widget.restrictions.end) >= now : true;
    return startValid && endValid;
};

export const textLinks = <T extends string | undefined>(value: T, refLookup: RefLookupFunction): T => {
    if (!value) return value;

    const { previewEnabed } = usePreview();

    // find all links
    const r = new RegExp(/<a data-item-id="([^"]+)" data-link-id="([^"]+)" href="">/, 'g');
    const textSplit = value?.match(r);

    if (textSplit) {
        // TODO: check if we can render <nuxt-link> programatically in place of a tags
        const postFix = previewEnabed.value ? '?preview=true' : '';
        for (const a of textSplit) {
            const m = a.match(/<a data-item-id="([^"]+)" data-link-id="([^"]+)" href="">/);

            const ret = m ? refLookup<{ id: string; url: UrlResolved }>(m[2], 'links') : null;
            if (ret && m) {
                const urlPath = ret.url.url === '/' ? '' : ret.url.url;
                const reg = new RegExp(`<a data-item-id="${m[1]}" data-link-id="${m[2]}" href="">`, 'ig');
                value = value?.replace(reg, `<a href="/${ret.url.locale}${urlPath}${postFix}" class="text-link woom-link">`) as T;
            }
        }
    }
    return value;
};

export const getMenuItemProps = (item: MenuItem) => {
    const localePath = useLocalePathWoom();
    const props: Record<string, any> = {
        key: `${item.id}`,
    };
    if (!item.items?.length) props.to = item.link?.type === 'url' ? (item.link?.data?.url as string) : localePath(item.link?.url?.url || '');
    if (item.color) props.style = { color: `#${item.color}` };

    return props;
};

/** Find item (Links, CMS references) reference by ID */
export const getItemById = <T extends { id: string }>(id: string, references: T[] | null | undefined) => {
    return references?.find((reference) => reference.id === id);
};

export type RefLookupLink = {
    id?: string;
    url?: LinkData['url'];
};
/** Get link for bikeFinderLink, howItWorksLink and resolve against the references */
export const getBikeFinderRefLink = (link: LinkData | undefined, references: RefLookupLink[] | null | undefined) => {
    if (!link || !references) return null;
    if (['sitemap', 'page'].includes(link.type)) {
        const urlReference = references.find((reference) => reference.id === link.data);
        return urlReference?.url?.url;
    }
};

export const ctaToButton = ({ size, styling, colorBg, colorLabel, link }: CallToAction) => {
    return {
        to: link.data?.url || link.url?.url,
        target: link?.data?.target,
        size: size as ButtonProps['size'],
        styling: styling.replace('_', '-') as ButtonProps['styling'],
        colorBg: colorBg || undefined,
        colorLabel: colorLabel || undefined,
    } as ButtonProps;
};
