export const addYears = (date: Date, years: number) => {
    date.setFullYear(date.getFullYear() + years);
    return date;
};
export const addDays = (date: Date, days: number) => {
    date.setDate(date.getDate() + days);
    return date;
};
export const addSeconds = (date: Date, seconds: number) => {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
};

export const getNow = (type: 'date' | 'iso' = 'date') => {
    const dateIsoString = new Date().toISOString();
    if (type === 'iso') return dateIsoString;
    return dateIsoString.split('T')[0];
};

export const dateDayDiff = (first: Date, second: Date) => {
    //                                                        ms     s    m    h
    return Math.floor((second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24));
};

export const isSameDay = (first: Date, second: Date) => {
    return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
};
