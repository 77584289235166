<script setup lang="ts">
    import type { Asset, AssetDataBynder, AssetDataBynderVideo, AssetDataKontentAi } from '~/@types/cms';

    export interface SharedImageProps {
        /** single string source for image  */
        src?: string;
        /** data Asset from CMS */
        data?: Asset;
        height?: string | number | undefined;
        width?: string | number | undefined;
        renderAs?: 'img' | 'picture';
        preset?: '16to9' | '3to2' | '2to3' | '1to1';
        noTransform?: boolean;
        cover?: boolean;
        sizes?: string;
        /** [Docs 📘](https://image.nuxt.com/usage/nuxt-img#preload) */
        imagePreload?: boolean;
        /** [Docs 📘](https://image.nuxt.com/usage/nuxt-img#loading) */
        imageLoading?: 'eager' | 'lazy';
        uspTitle?: string;
        gtmClick?: string;
    }

    export interface ImageProps extends SharedImageProps {
        contain?: boolean;
        types?: string[];
        modifiers?: any;
        alt?: string;
    }

    const props = withDefaults(defineProps<ImageProps>(), {
        renderAs: 'picture',
        modifiers: {},
        sizes: undefined,
        preset: undefined,
        alt: '',
        placeholder: false,
        imagePreload: false,
        imageLoading: 'lazy',
    });

    const emit = defineEmits(['error']);

    const imageLoaded = ref(false);

    const img = useImage();

    const provider = computed(() => {
        const src = unref(_src);
        if (props.noTransform || !src) return;
        if (src.startsWith('https://mediahub.woom.com')) return 'mediahub';
        if (src.startsWith('https://assets.woom.com')) return 'assets';
        if (src.indexOf('kc-usercontent.com') > 0) return 'kentico';
        if (src.startsWith('https://cdn.shopify.com/')) return 'shopify';
    });

    // the final source comes from either the props.src or assembled through the Asset object
    const _src = computed(() => {
        if (!props.data) return props.src;
        if (typeof props.data === 'string') return props.data;

        if (typeof props.data !== 'object') return;

        const d = props.data;
        if (isAssetDataYouTubeVideo(d)) return;
        if (isAssetKontentAi(d)) {
            return d.data.src;
        }
        const baseUrl = d.data.baseUrl || '';
        if (isAssetBynder(d)) {
            const imageData = d.data;
            type ImageDataKeys = keyof typeof imageData;
            const getBestPreset = (presets: string[]) => (presets.find((preset) => imageData[preset as ImageDataKeys]) || 'image') as ImageDataKeys;
            const presetMap: Record<string, string[]> = {
                '1to1': ['1to1', '3to2'],
                '3to2': ['3to2'],
                '2to3': ['2to3', '1to1', '3to2'],
                '16to9': ['16to9', '3to2'],
            };
            const imagePreset = getBestPreset(presetMap[props.preset || '16to9'] || []);
            return `${baseUrl}${imageData[imagePreset]}`;
        }
        if (isAssetDataBynderVideo(d)) {
            return `${baseUrl}${d.data.image}`;
        }
        console.warn('Unknown Asset', d);
    });

    const handleError = () => {
        emit('error');
        imageLoaded.value = true;
    };

    // const { data } = await useAsyncData(`thumbhash-${_src.value}`, async () => {
    //     if (!_src.value || !provider.value) return { image: '' };
    //     const imageUrl = img(
    //         _src.value,
    //         {
    //             width: 100,
    //             height: 100,
    //         },
    //         {
    //             provider: provider.value,
    //         },
    //     );

    //     const response = await $fetch('/api/thumbhash', {
    //         query: {
    //             url: imageUrl,
    //         },
    //     });

    //     if (!response) {
    //         return { image: '' };
    //     }
    //     return { image: response };
    // });

    const base64Image = computed(() => ''); // data.value?.image);
    const isPng = computed(() => _src.value?.endsWith('.png'));
</script>
<template>
    <nuxt-picture
        v-if="_src && renderAs === 'picture'"
        :src="_src"
        :provider="provider"
        :format="provider === 'kentico' ? 'webp,jpg' : provider === 'shopify' ? 'png' : undefined"
        :alt="alt"
        :width="width"
        :height="height"
        :preload="imagePreload"
        :loading="imageLoading"
        :modifiers="{ fit: 'cover' }"
        :img-attrs="{
            'data-usp-title': uspTitle,
            class: [
                gtmClick ? gtmClick : '',
                cover ? 'w-full h-full object-cover object-center bg-cover bg-no-repeat bg-center' : 'bg-cover bg-no-repeat bg-center',
            ],
            style: { backgroundImage: !isPng && !imageLoaded && base64Image ? `url('${base64Image}')` : undefined },
        }"
        :sizes="sizes"
        @load="() => (imageLoaded = true)"
        @error="handleError" />
    <nuxt-img
        v-else-if="_src"
        :src="_src"
        :provider="provider"
        :alt="alt"
        :width="width"
        :height="height"
        :preload="imagePreload"
        :loading="imageLoading"
        :class="[gtmClick ? gtmClick : '', { 'animate-pulse': !imageLoaded }]"
        :modifiers="{ fit: cover ? 'cover' : contain ? 'contain' : undefined, ...modifiers }"
        :sizes="sizes"
        :data-usp-title="uspTitle"
        :placeholder="base64Image"
        @load="() => (imageLoaded = true)"
        @error="handleError" />
</template>
