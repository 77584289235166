export const useLocales = () => {
    const { locale, locales, t, te } = useI18n();

    const days = 60;

    watch(
        () => locale.value,
        () => {
            let expires = '';
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = 'lang=' + `${locale.value}` + expires + '; path=/';
        },
    );

    const appState = useAppStore();

    const matchingLocale = computed(() => {
        for (const localeItem of locales.value) {
            if (typeof localeItem === 'object') {
                if (locale.value === localeItem.code) {
                    return localeItem;
                }
            }
        }
    });

    const isoCode = computed(() => {
        const code = matchingLocale.value?.code.replace(/_/g, '-').toLowerCase();
        return code || 'de-de';
    });

    const isoLocale = computed(() => {
        return matchingLocale.value?.iso || 'de';
    });

    const iso2Country = computed<string>(() => {
        return matchingLocale.value?.countryISO2 || 'DE';
    });

    let metric: boolean;
    let imperial: boolean;
    const units = appState.website?.shop?.units;
    if (!units) {
        metric = true;
        imperial = true;
    } else {
        metric = units.includes('metric');
        imperial = units.includes('imperial');
    }

    const languageCountrySplit = computed(() => locale.value.toLowerCase().split('_'));
    const language = computed(() => languageCountrySplit.value[0]);
    const country = computed(() => languageCountrySplit.value[1]);

    const languageName = computed(() => getLanguageNativeName(language.value));

    const isUS = ref(iso2Country.value.toLowerCase() === 'us');

    const n = (num: number) => {
        return new Intl.NumberFormat(isoLocale.value, { maximumSignificantDigits: 2 }).format(num);
    };

    return {
        locale,
        locales,
        /** language in lowercase derived from the current locale */
        language,
        /** country in lowercase derived from the current locale */
        country,
        localeSuffix: locale.value.substring(locale.value.indexOf('_') + 1),
        /** translation function from nuxt i18n */
        t,
        /** translation exists from nuxt i18n */
        te,
        translateSafe: (translationKey: string, fallback: string) => {
            const translation = t(translationKey);
            return translation === translationKey ? fallback : translation;
        },
        /** date formatting */
        d: (d: string | Date): string => {
            const date = typeof d === 'string' ? Date.parse(d) : d;
            return new Intl.DateTimeFormat(isoLocale.value).format(date);
        },
        /** money formatting */
        m: (num: number, currencyCode: string) => {
            return new Intl.NumberFormat(isoLocale.value, {
                style: 'currency',
                currency: currencyCode,
            }).format(num);
        },
        /** number formatting */
        n,
        /** size formatting */
        s: (size: number | [number, number], unitIsImperial: boolean = false) => {
            const formatter = new Intl.NumberFormat(isoLocale.value, {
                style: 'unit',
                unit: unitIsImperial ? 'inch' : 'centimeter',
            });
            if (Array.isArray(size)) {
                return `${n(size[0])} - ${formatter.format(size[1])}`;
            } else {
                return formatter.format(size);
            }
        },
        /** weight formatting */
        w: (weight: string, unitIsImperial: boolean = false) => {
            const parsedWeight = parseFloat(weight);
            return new Intl.NumberFormat(isoLocale.value, {
                style: 'unit',
                unit: unitIsImperial ? 'pound' : 'kilogram',
            }).format(parsedWeight);
        },
        isoCode,
        isoLocale,
        iso2Country,
        languageName,
        isUS,
        flag: 'fi ' + `fi-${iso2Country.value.toLowerCase()}`,
        metric,
        imperial,
        isInternational: locale.value.toUpperCase().indexOf('_INT') > 1,
    };
};

export const useLocalePathWoom = () => {
    const localePath = useLocalePath();

    return (...props: Parameters<typeof localePath>) => {
        const path = props[0];
        let route = localePath(...props);
        if (path === '/' || (typeof path === 'object' && path.path === '/')) {
            route += '/';
        }
        return route;
    };
};
