import type { Asset, CurrencyCode, Product, ProductLabel, ProductPreview, ProductVariant } from '~/graphql/generated';

export const hiddenProductAttributes: (string | number)[] = ['Edition', 'Revision'];

const getProductImage = (
    product: {
        options: CMSProduct['options'];
        variants: CMSProduct['variants']['items'];
    },
    defaultImage = '',
) => {
    let productImage = '';
    const colorOptionKey = Object.keys(product.options ?? {}).find((option) => option.includes('Color'));
    if (colorOptionKey && product.options?.[colorOptionKey]) {
        for (const color of product.options[colorOptionKey]) {
            const variant = product.variants.find((variant) => variant.selectedOptions?.[colorOptionKey]?.includes(color));
            if (!variant?.image) continue;
            productImage = variant.image;
            break;
        }
    }
    return productImage || product.variants[0]?.image || defaultImage;
};

const isProductPreviewType = (product: Partial<Product | ProductPreview>): product is Partial<ProductPreview> => 'content' in product;

const parseVariants = (
    title: string,
    formattedTitle: string,
    types: string[],
    defaultImage: string | undefined,
    variants: ProductVariant[] | undefined,
    isoLocale: string,
) => {
    // TODO: check if can be removed - currently unused orderCodes
    // const appStore = useAppStore();
    // const orderCodes = appStore.orderCodes;
    // order codes to check if we can enable an existing variant
    let hasPreOrder = false;
    let hasCustomNaming = false;
    let hasNotifyMe = false;

    const normalizedVariants: CMSProductVariant[] = [];
    const productOptions: CMSProduct['options'] = {};
    const optionsSequence: CMSProduct['optionsSequence'] = [];

    let currencyCode;
    let minPrice = -1;
    if (Array.isArray(variants)) {
        for (const variant of variants) {
            if (!variant || variant.hidden) continue;
            if (variant?.customNaming) {
                hasCustomNaming = true;
            }
            if (variant?.preOrder) {
                hasPreOrder = true;
            }
            if (variant?.notifyMe) {
                hasNotifyMe = true;
            }
            // skip the following props: weight, weightUnit, currentlyNotInStock, unitPrice
            let state: ProductAvailabilityType = variant.availabilityState;
            if (state === ProductAvailability.LOCKED) {
                // TODO: check if can be removed - currently unused
                // if (orderCodes && orderCodes.includes(variant.orderCode)) {
                //     state = variant.preOrder && variant.shippingDate ? ProductAvailability.PREORDER : ProductAvailability.AVAILABLE;
                // } else {
                //     state = ProductAvailability.OUT_OF_STOCK;
                // }
                state = ProductAvailability.OUT_OF_STOCK;
            }
            // notify me check
            let canBeNotified = variant.notifyMe;
            if (state === ProductAvailability.OUT_OF_STOCK) {
                state = variant.notifyMe ? ProductAvailability.NOTIFY_ME : ProductAvailability.END_OF_LIFE;
            }
            // normalize the selected product options
            const selectedOptions: CMSProductVariant['selectedOptions'] = {};
            for (const selectedOption of variant?.selectedOptions ?? []) {
                if (!selectedOption?.name || !selectedOption.value) continue;
                if (!optionsSequence.includes(selectedOption.name)) {
                    optionsSequence.push(selectedOption.name);
                }
                if (!productOptions[selectedOption.name]) {
                    productOptions[selectedOption.name] = [selectedOption.value];
                } else if (!productOptions[selectedOption.name].includes(selectedOption.value)) {
                    productOptions[selectedOption.name].push(selectedOption.value);
                }
                if (selectedOption.name === 'Title' && selectedOption.value === DEFAULT_TITLE) {
                    continue;
                }
                selectedOptions[selectedOption.name] = selectedOption.value;
            }
            const idx = variant.id.lastIndexOf(':');
            const hash = variant.id && idx > 0 ? variant.id.substring(idx + 1) : null;
            const variantId = hash ? getNativeVariantId(hash) : undefined;
            const shopTitle = variant.shopTitle !== DEFAULT_TITLE ? variant.shopTitle : '';
            const vTitle = shopTitle !== '' ? `${title} - ${shopTitle}` : title;
            const fTitle = shopTitle !== '' ? `${formattedTitle} - ${shopTitle}` : formattedTitle;
            const shopImage = variant.shopImage !== '' ? variant.shopImage : undefined;
            const image = isProductBike({ types }) && variant.sku !== '' ? getAssetUrl(`${variant.sku}-side`) : resolveImage(variant) || defaultImage;
            const priceNumber = variant.price?.amount ? Number.parseFloat(variant.price.amount) * 100 : 0;
            const amount = variant.price?.amount ? Number.parseFloat(variant.price.amount) : 0;
            const compareAt = variant.compareAtPrice?.amount ? Number.parseFloat(variant.compareAtPrice.amount) : 0;
            // define the minimum price to be displayed on the product
            if (priceNumber > 0 && (priceNumber < minPrice || minPrice === -1)) {
                minPrice = priceNumber;
                currencyCode = variant.price?.currencyCode;
            }
            const normalizedVariant: CMSProductVariant = {
                id: variant.id,
                nativeId: variant.nativeId as string,
                variantId,
                sku: variant.sku as string,
                barcode: variant.barcode as string,
                availabilityState: state,
                image,
                shopImage: shopImage as string,
                requiresShipping: Boolean(variant.requiresShipping),
                variantTitle: shopTitle as string,
                title: vTitle,
                formattedTitle: fTitle,
                priceNumeric: priceNumber,
                currency: currencyCode as CurrencyCode,
                price:
                    variant.price?.amount && variant.price?.amount !== ''
                        ? new Intl.NumberFormat(isoLocale, { style: 'currency', currency: variant.price.currencyCode }).format(
                              Number(variant.price.amount),
                          )
                        : undefined,
                compareAtPrice:
                    variant.compareAtPrice?.amount && variant.compareAtPrice?.amount !== '' && amount < compareAt
                        ? new Intl.NumberFormat(isoLocale, { style: 'currency', currency: variant.compareAtPrice.currencyCode }).format(
                              Number(variant.compareAtPrice.amount),
                          )
                        : undefined,
                canBeNotified,
                canBeOrdered: state === ProductAvailability.AVAILABLE || state === ProductAvailability.PREORDER,
                redCharity: variant.redCharity,
                shippingDate: variant.shippingDate ? new Date(variant.shippingDate) : undefined,
                availabilityDate: variant.availabilityDate ? new Date(variant.availabilityDate) : undefined,
                selectedOptions,
                // TODO: check if we can find a better way than a type cast
                labels: variant.labels as ProductLabel[],
                quantityAvailable: variant.quantityAvailable ?? 0,
            };
            if (state === ProductAvailability.PREORDER) {
                if (normalizedVariant.shippingDate) {
                    const diff = dateDayDiff(normalizedVariant.shippingDate, new Date());
                    if (diff > 0) {
                        normalizedVariant.preorderDate = new Intl.DateTimeFormat(isoLocale).format(addDays(normalizedVariant.shippingDate, 7));
                    }
                }
            }
            normalizedVariants.push(normalizedVariant);
        }
    }
    // remove the default title option on single variants
    if (productOptions.Title && productOptions.Title.length === 1 && productOptions.Title[0] === DEFAULT_TITLE) {
        delete productOptions.Title;
        if (optionsSequence.includes('Title')) {
            optionsSequence.splice(optionsSequence.indexOf('Title'), 1);
        }
    }
    if (minPrice > 0) {
        minPrice = Math.round(minPrice) / 100;
    }

    return {
        variants: normalizedVariants,
        productOptions,
        optionsSequence,
        minPrice,
        currencyCode,
        hasPreOrder,
        hasCustomNaming,
        hasNotifyMe,
    };
};

export const canVariantQuickSell = (prod?: CMSProduct, variantId?: string) => {
    if (!variantId) return false;
    if (!prod) return false;
    if (prod.customNaming) return false;
    // custom drop down for custom attribute
    if (prod.tags?.includes('pdp-original-selection')) return false;
    const variant = prod.variants.items.find((v: CMSProductVariant) => v.id === variantId);
    if (variant) {
        return variant.availabilityState === ProductAvailability.AVAILABLE;
    }
    return false;
};

export const filterProductColor = <T extends Partial<Product | ProductPreview>>(items?: T[], colorFilter?: string) => {
    if (!colorFilter) return items;

    const fallbackColor = '*';

    return items?.reduce((newItems, currentItem) => {
        const colorOptionIndex = currentItem.options?.findIndex((option) => option?.name === 'Color') ?? -1;
        if (colorOptionIndex < 0 || !currentItem.options || !currentItem.options[colorOptionIndex]) return newItems;

        // Filter color options on product
        currentItem.options[colorOptionIndex].values = currentItem.options[colorOptionIndex].values?.filter((color) =>
            colorFilter.includes(color ?? fallbackColor),
        );

        // Update variants
        const variants = currentItem.variants?.items?.filter((variant) =>
            colorFilter.includes(variant?.selectedOptions?.find((option) => option?.name === 'Color')?.value ?? fallbackColor),
        );

        newItems.push({
            ...currentItem,
            variants: variants ? { items: variants, total: variants.length } : null,
        });
        return newItems;
    }, [] as T[]);
};

/**
 * This function handles all the differences in the CMSProduct conversion between `Partial<Product>` and `Partial<ProductPreview>`
 */
const getCMSProductBaseInfo = (product: Partial<Product | ProductPreview>) => {
    if (isProductPreviewType(product)) {
        return {
            productTitle: product.content?.title,
            types: product.content?.types || ['Undefined'],
            defaultImage: getDefaultImage(product.content?.overview),
            productDescription: product.content?.description,
            category: product.content?.category || [],
            kenticoImage: product.content?.overview?.data?.src,
            carouselDefaultImage: getDefaultImage(product.content?.overview),
            defaultHover: getDefaultImage(product.content?.hover),
            url: product.content?.url.url,
        };
    }
    return {
        productTitle: product.title,
        types: product.types || ['Undefined'],
        defaultImage: getDefaultImage(product.shopImage),
        productDescription: product.description,
        category: product.category || [],
        kenticoImage: product.overview?.data?.src,
    };
};

export const getDefaultImage = (image: string | Asset | null | undefined): string | undefined => {
    if (!image) return;
    if (typeof image === 'string') return image;
    if (typeof image === 'object') {
        if (image.id?.indexOf('a:') === 0) {
            return image.data.src || undefined;
        } else if (image.id?.indexOf('b:') === 0) {
            return `${image.data.baseUrl}${image.data['1to1']}`;
        }
    }
};

const getNativeGenericId = (id: string | null | undefined, identifier: string) => {
    if (!id) return;
    const parsedId = id.startsWith(identifier) ? id : atob(id);
    return parsedId.substring(identifier.length);
};

export const getNativeProductId = (id: string | null | undefined) => {
    return getNativeGenericId(id, 'gid://shopify/Product/');
};

export const getNativeVariantId = (id: string | null | undefined) => {
    return getNativeGenericId(id, 'gid://shopify/ProductVariant/');
};

export const isProductBike = (product: { types?: string[] | null } | undefined | null = {}) => Boolean(product?.types?.includes('Bike'));

export const parseTags = (product: Partial<Product> | Partial<ProductPreview>): string[] => {
    const tags: string[] = [];
    if (product.tags) {
        for (const tag of product.tags) {
            if (tag) {
                tags.push(tag);
            }
        }
    }
    return tags;
};

export const processApiProduct = (product: Partial<Product | ProductPreview>, isoLocale: string) => {
    const { defaultHover, carouselDefaultImage, category, defaultImage, kenticoImage, productTitle, productDescription, types, url } =
        getCMSProductBaseInfo(product);
    const title = product.shopTitle || 'Undefined Title';
    const formattedTitle = productTitle || title;
    const description = productDescription || '';

    // filter variants that are hidden
    const { variants, productOptions, optionsSequence, minPrice, currencyCode, hasPreOrder, hasCustomNaming, hasNotifyMe } = parseVariants(
        title,
        formattedTitle,
        types,
        defaultImage,
        (product.variants?.items ?? []) as ProductVariant[],
        isoLocale,
    );
    const price = minPrice > 0 ? new Intl.NumberFormat(isoLocale, { style: 'currency', currency: currencyCode }).format(minPrice) : undefined;

    const productImage = getProductImage(
        {
            options: productOptions,
            variants,
        },
        carouselDefaultImage,
    );

    return {
        id: product.id as string,
        nativeId: product.nativeId as string,
        productId: getNativeProductId(product.nativeId),
        handle: product.handle as string,
        title,
        formattedTitle,
        description,
        price,
        priceNumeric: minPrice,
        currency: currencyCode as CurrencyCode,
        types,
        category,
        productType: product.productType || 'Undefined',
        labels: product.labels as ProductLabel[],
        limit: product.limit as number,
        tags: parseTags(product),
        url,
        // images
        kenticoImage,
        image: productImage,
        hover: defaultHover,
        // from variant parsing
        options: productOptions,
        optionsSequence,
        variants: {
            total: variants.length,
            items: variants,
        },
        // boolean values
        isGiftCard: false,
        forSale: true,
        preOrder: !!hasPreOrder || product.preOrder,
        notifyMe: !!hasNotifyMe || product.notifyMe,
        customNaming: !!hasCustomNaming || product.customNaming,
    } as CMSProduct;
};

export const processApiCollection = (
    apiProducts: Array<Partial<ProductPreview>>,
    isoLocale: string,
    bundleUsage: boolean = false,
): Array<CMSProduct> => {
    const ret: CMSProduct[] = [];
    for (const product of apiProducts) {
        if (!product) continue;
        const item = processApiProduct(product, isoLocale);
        if (item) {
            ret.push(item);
        }
    }
    return bundleUsage ? ret : ret.filter((p: CMSProduct) => p.url && p.url !== '');
};

export const resolveImage = (variant: ProductVariant): string | undefined => {
    const galleryData = variant.content?.gallery?.[0]?.data;
    if (!galleryData) return;
    const selectedSize = galleryData['1to1'] || galleryData['image'];
    return (galleryData.baseUrl ?? '') + selectedSize;
};
