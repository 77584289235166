import type { AssetDataBynder, AssetDataBynderVideo, AssetDataKontentAi, AssetDataYouTubeVideo, AssetDataIcon, Asset } from '~/@types/cms';

export function getAssetUrl(imageName: string, category = 'products') {
    return `https://assets.woom.com/${category}/${imageName}.png`;
}

export const nuxtImageSizes = {
    carousel: '400 md:512 lg:720 2xl:1200',
};

export function isAssetBynder(asset: { id: string }): asset is AssetDataBynder {
    return asset.id.startsWith('b:');
}
export function isAssetDataBynderVideo(asset: { id: string }): asset is AssetDataBynderVideo {
    return asset.id.startsWith('video:');
}
export function isAssetDataYouTubeVideo(asset: { id: string }): asset is AssetDataYouTubeVideo {
    return asset.id.startsWith('yt:');
}
export function isAssetKontentAi(asset: { id: string }): asset is AssetDataKontentAi {
    return asset.id.startsWith('a:');
}
export function isAssetDataIcon(asset: { id: string }): asset is AssetDataIcon {
    return asset.id.startsWith('icon:');
}

export const resolveAssetSrc = (asset: Asset, bynderType: 'image' | '1to1' | '16to9' | '9to16' | '2to3' | '3to2' = 'image'): string | undefined => {
    if (typeof asset === 'string' || !asset) return;
    if (isAssetKontentAi(asset)) return asset.data.src;
    else if (isAssetBynder(asset)) return `${asset.data.baseUrl}${asset.data[bynderType] ? asset.data[bynderType] : asset.data.image}`;
};
